
export const serverAppConfig = {
  "storeType": "B2C",
  "language": "sv",
  "paths": {
    "brandsUrlPath": "/varumarken",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/t",
    "productsUrlPath": "/p",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "brands",
      "label": "Varumärken",
      "path": "/varumarken"
    },
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blog"
    },
    {
      "id": "minasidor",
      "label": "Mina sidor",
      "path": "/account"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1033/tags/vinlagring_svart.webp",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris högt-lågt",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lågt-högt",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyaste",
      "default": false
    }
  ],
  "desktopItemHeight": "25vw",
  "mobileItemHeight": "75vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 999,
  "shippingProviderConfig": {
    "provider": "INGRID",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 999,
      "deliveryFee": 139
    },
    "ingridOptions": null
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCountry": "SE",
        "purchaseCurrency": "SEK",
        "phoneMandatory": true,
        "colorButton": null
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    },
    "hero": {
      "base": "92vh",
      "sm": "81vh",
      "md": "60vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/vinlagring/?view_public_for=104695747628337"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/vinlagring/?hl=sv"
    }
  ],
  "topNavPanel": {
    "items": [
      {
        "icon": "FaCrown",
        "label": "Om oss",
        "href": "/about-us"
      },
      {
        "icon": "MdOutlineLocalShipping",
        "label": "Fri frakt över 999 kr"
      },
      {
        "icon": "FaCrown",
        "label": "VÅRA SHOWROOM",
        "href": "/showrooms"
      }
    ]
  }
};
export const serverTheme = {}; 
